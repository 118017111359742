<script setup lang="ts">
import { tv } from "tailwind-variants"

defineProps<{
  class?: string
}>()

const classes = tv(
  {
    base: "h-max w-full max-w-lg space-y-8 self-center rounded-2xl bg-white px-12 py-12",
  },
  tvConfig,
)
</script>

<template>
  <div :class="classes({ class: $props.class })">
    <slot />
  </div>
</template>
