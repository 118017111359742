<script setup lang="ts">
const emit = defineEmits<{
  (event: "submit"): void
  (event: "close", isOpen: boolean): void
}>()

const props = withDefaults(defineProps<{ open: boolean; zBase?: number }>(), {
  zBase: 50,
})

const mouseDown = ref(false)

function onClick(evt: MouseEvent) {
  if (evt.target === evt.currentTarget && mouseDown.value) {
    emit("close", false) // The event is named "close" but is really "is open"
  }
}

function onMouseDown(evt: MouseEvent) {
  if (evt.target === evt.currentTarget) {
    mouseDown.value = true
    window.addEventListener("mouseup", onMouseUp)
  }
}

function onMouseUp() {
  requestAnimationFrame(() => {
    mouseDown.value = false
    window.removeEventListener("mouseup", onMouseUp)
  })
}

function onKeyDown(evt: KeyboardEvent) {
  if (evt.key === "Escape") {
    emit("close", false)
  }
}

function closeModal() {
  const html = document.body.parentNode as HTMLHtmlElement
  document.removeEventListener("keydown", onKeyDown)
  setTimeout(() => {
    document.body.classList.remove("overflow-hidden")
    html.style.scrollbarGutter = "initial"
    mouseDown.value = false
  }, 200)
}

watch(
  () => props.open,
  (isOpen) => {
    mouseDown.value = false
    const html = document.body.parentNode as HTMLHtmlElement
    if (isOpen === true) {
      document.addEventListener("keydown", onKeyDown)
      document.body.classList.add("overflow-hidden")
      html.style.scrollbarGutter = "stable"
    } else {
      closeModal()
    }
  },
)

onBeforeUnmount(closeModal)
</script>

<template>
  <div
    :class="[
      open
        ? 'opacity-1'
        : 'pointer-events-none [&_*]:!pointer-events-none opacity-0',
      'fixed left-0 top-0 w-full h-full !m-0 overflow-y-auto transition-opacity duration-200 flex justify-center items-center',
    ]"
    :style="{
      zIndex: zBase,
    }">
    <div
      class="fixed inset-0 bg-black/30"
      aria-hidden="true"
      @click="onClick"
      @mousedown="onMouseDown" />
    <div class="max-h-screen min-h-max">
      <div
        class="relative flex justify-center p-4"
        :style="{
          zIndex: zBase + 10,
        }">
        <slot />
      </div>
    </div>
  </div>
</template>
